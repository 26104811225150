import React, { Component } from "react";
import { Link, Element } from "react-scroll";
import logo from "./logo.svg";
import companylogo from "./images/logo.png";
import bannerimg from "./images/hero-background.png";
import bannerimgforeground from "./images/hero-foreground.png";
import bannermobileimg from "./images/banner1.png";
import bannershape from "./images/banner-image.png";
import bannericon1 from "./images/sketch.png";
import bannericon2 from "./images/xd.png";
import bannericon3 from "./images/figma.png";

import builtcard1 from "./images/Launch-fast.svg";
import builtcard2 from "./images/Customize.svg";
import builtcard3 from "./images/Pre-Built.svg";

import logoicon1 from "./images/badgeP.png";
import logoicon2 from "./images/instacart.png";
import logoicon3 from "./images/yelp.png";
import logoicon4 from "./images/Y.png";
import logoicon5 from "./images/Clearcover.png";

import upcomingimage from "./images/upcoming.png";
import upcomingstaricon from "./images/star-icon.png";
import emailimage from "./images/email_asset.png";
import oneimage from "./images/one.png";

import companydribblelogo from "./images/dribble.png";
import companyinstalogo from "./images/instagram.png";
import companytwitterlogo from "./images/twitter.png";
import faqFooterImage from "./images/app-view1.png";
import footershape from "./images/shape.png";
import footerlogo from "./images/footer-logo.svg";
import loading from "./images/loading.gif";

import "./App.css";

import OnImagesLoaded from "react-on-images-loaded";
import { Button } from "reactstrap";
import {
  Container,
  Navbar,
  Nav,
  Card,
  Row,
  Col,
  Image,
  ListGroup,
  Item,
  Tab,
} from "react-bootstrap";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      toggleStarter: true,
      togglePremium: false,
      toggleCombo: false,
    };
  }
  setLoadingFalse = () => {
    this.setState({ isLoading: false });
  };
  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }
  showStater = () => {
    this.setState({
      toggleStarter: true,
      togglePremium: false,
      toggleCombo: false,
    });
  };
  showPremium = () => {
    this.setState({
      togglePremium: true,
      toggleStarter: false,
      toggleCombo: false,
    });
  };
  showCombo = () => {
    this.setState({
      togglePremium: false,
      toggleStarter: false,
      toggleCombo: true,
    });
  };
  render() {
    return (
      <>
        {this.state.isLoading && (
          <div className="loading-container">
            <img className="loadingShow" src={loading} />
          </div>
        )}
        <OnImagesLoaded
          onLoaded={this.setLoadingFalse}
          onTimeout={this.setLoadingFalse}
          timeout={7000}
          delay={1000}
        >
          <div className="App">
            <header className="Main-header">
              <Navbar expand="lg" variant="light">
                <Container>
                  <Navbar.Brand href="http://eatcaviar.co/">
                    <img
                      src={companylogo}
                      width="160px"
                      className="img-fluid d-inline-block align-top"
                      alt="Socially"
                    />
                  </Navbar.Brand>
                  <a href="#pricing" target="_blank">
                    <Button
                      type="button"
                      className="btn btn-theme social_button_mob"
                    >
                      Get Socially
                    </Button>
                  </a>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="bg-transparent"
                  >
                    <Nav className="mx-auto">
                      <Nav.Link
                        href="https://socially-demo.eatcaviar.co"
                        target="_blank"
                      >
                        Live demo
                      </Nav.Link>

                      <Nav.Link>
                        <Link
                          activeClass="active"
                          to="pricing"
                          smooth={true}
                          offset={100}
                          duration={500}
                        >
                          Pricing
                        </Link>
                      </Nav.Link>
                    </Nav>
                    <Link
                      activeClass="active"
                      to="pricing"
                      smooth={true}
                      offset={100}
                      duration={500}
                    >
                      <Button
                        type="button"
                        className="btn btn-theme btn-nav-theme"
                      >
                        Get Socially
                      </Button>
                    </Link>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </header>
            <main>
              <div id="banner-section">
                <Row className="align-items-start justify-content-start">
                  <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
                    <div class="main-banner-content">
                      <h1 class="text-headings">
                        A modern Social UI kit built in React
                      </h1>
                      <p class="heading-para text-headings">
                        React web and mobile design kit to kickstart your social
                        app projects.
                      </p>

                      <Link
                        activeClass="active"
                        to="pricing"
                        smooth={true}
                        offset={100}
                        duration={500}
                      >
                        <Button type="button" className="btn btn-theme w-175">
                          Get Socially
                        </Button>
                      </Link>
                      <a
                        href="https://socially-demo.eatcaviar.co"
                        target="_blank"
                      >
                        <Button
                          type="button"
                          className="btn btn-theme-grey w-175"
                        >
                          View Our Demo
                        </Button>
                      </a>
                      <Row className="ml-1">
                        <Col xs="auto" className="p-1">
                          <p class="heading-para pt-1 text-headings">
                            Available for:
                          </p>
                        </Col>
                        <Col xs="auto" className="p-1">
                          <ul class="list-style-none banner-icons">
                            <li>
                              <img src={bannericon1} class="img-fluid" />
                            </li>
                            <li>
                              <img src={bannericon2} class="img-fluid" />
                            </li>
                            <li>
                              <img src={bannericon3} class="img-fluid" />
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
                    <div class="main-banner-image-foreground-desktop">
                      <img src={bannerimgforeground} class="img-fluid" />
                    </div>
                    <div class="main-banner-image">
                      <img src={bannerimg} class="img-fluid" />
                    </div>
                    <div class="main-banner-mobile-image">
                      <img src={bannermobileimg} class="img-fluid" />
                    </div>
                  </Col>
                </Row>
              </div>
              <div id="built-card-section">
                <Container>
                  <Row>
                    {/* <Col md="12">
                    <h2 class="text-left text-headings">
                      {" "}
                      Pre-built social{" "}
                      <span class="d-block"> pages for all </span>{" "}
                      <span class="d-block"> occasions</span>
                    </h2>
                  </Col> */}
                    <Col md="4">
                      <Card className="card-built">
                        <Card.Img variant="top" src={builtcard1} />
                        <Card.Body>
                          <Card.Title>
                            <h3 class="text-headings">Launch Fast</h3>
                          </Card.Title>
                          <Card.Text style={{ maxWidth: 300, margin: "auto" }}>
                            <p class="text-headings">
                              Quick-start your next social project with
                              beautifully designed production-ready templates.
                            </p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="4" sm="12">
                      <Card className="card-built">
                        <Card.Img variant="top" src={builtcard2} />
                        <Card.Body>
                          <Card.Title>
                            <h3 class="text-headings">Fully Customizable</h3>
                          </Card.Title>
                          <Card.Text style={{ maxWidth: 300, margin: "auto" }}>
                            <p class="text-headings">
                              All elements and code are neatly and cleanly
                              prepared, all you need to do is create your own
                              page in minutes.
                            </p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="4" sm="12">
                      <Card className="card-built">
                        <Card.Img variant="top" src={builtcard3} />
                        <Card.Body>
                          <Card.Title>
                            <h3 class="text-headings">
                              Premium Design and Code
                            </h3>
                          </Card.Title>
                          <Card.Text style={{ maxWidth: 300, margin: "auto" }}>
                            <p class="text-headings">
                              Designed and written from scratch, lightweight as
                              possible. Uses HTML5, CSS3 and React.
                            </p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mt-md-4">
                    <Col md="12" className="text-center">
                      <Link
                        activeClass="active"
                        to="pricing"
                        smooth={true}
                        offset={100}
                        duration={500}
                      >
                        <Button type="button" className="btn btn-theme">
                          Get Socially
                        </Button>
                      </Link>
                      <a
                        href="https://socially-demo.eatcaviar.co"
                        target="_blank"
                      >
                        <Button type="button" className="btn btn-theme-grey">
                          View Our Demo
                        </Button>
                      </a>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md="12">
                      <p className="text-paragraph built-by-line">
                        BUILD BY DESIGNERS AND DEVELOPERS WHO HAVE WORKED WITH
                        WORLD LEADING COMPANIES{" "}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Image
                        src={logoicon1}
                        className="img-fluid logo-image"
                        alt="Logo Image"
                      />
                    </Col>
                    <Col>
                      <Image
                        src={logoicon2}
                        className="img-fluid logo-image"
                        alt="Logo Image"
                      />
                    </Col>
                    <Col>
                      <Image
                        src={logoicon3}
                        className="img-fluid logo-image"
                        alt="Logo Image"
                      />
                    </Col>
                    <Col>
                      <Image
                        src={logoicon4}
                        className="img-fluid logo-image"
                        alt="Logo Image"
                      />
                    </Col>
                    <Col>
                      <Image
                        src={logoicon5}
                        className="img-fluid logo-image"
                        alt="Logo Image"
                      />
                    </Col>
                  </Row>
                </Container>
              </div>

              <div id="upcoming-section">
                <Container>
                  <Row>
                    <Col md="12">
                      <h2 className="text-white">Upcoming Releases</h2>
                      <p className="heading-para text-heading-paragraph">
                        Our aim is to make this UI Kit Roadmap open to the
                        public{" "}
                        <span class="d-block">
                          and give you a{" "}
                          <span class="text-theme">
                            clear understanding where{" "}
                          </span>
                          we are heading.
                        </span>{" "}
                      </p>
                    </Col>
                  </Row>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row className="mt-5">
                      <Col sm={12} md={6} lg={6}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Image
                              src={upcomingimage}
                              className="imageHome"
                              alt="Upcoming Banner"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <Image
                              src={emailimage}
                              className="imageHome"
                              alt="Upcoming Banner"
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="three">
                            <Image
                              src={oneimage}
                              className="imageHome"
                              alt="Upcoming Banner"
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">
                              <Card className="upcoming-card">
                                <Card.Body>
                                  <span class="tip tip-left"></span>
                                  <Card.Title>
                                    <p class="text-bold">
                                      Night Mode on Every Screen
                                    </p>
                                  </Card.Title>
                                  <Card.Text>
                                    <p class="para-small">
                                      Every page on socially will be available
                                      for night mode in the next release.
                                    </p>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">
                              <Card className="upcoming-card">
                                <Card.Body>
                                  <span class="tip tip-left"></span>
                                  <Card.Title>
                                    <p class="text-bold">
                                      Beautiful Email Templates
                                    </p>
                                  </Card.Title>
                                  <Card.Text>
                                    <p class="para-small">
                                      Beautiful email templates designed for you
                                      needs to{" "}
                                      <span class="text-theme">
                                        empower your communication
                                      </span>{" "}
                                      with your users via email.
                                    </p>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="three">
                              <Card className="upcoming-card">
                                <Card.Body>
                                  <span class="tip tip-left"></span>
                                  <Card.Title>
                                    <p class="text-bold">
                                      Social calendar page
                                    </p>
                                  </Card.Title>
                                  <Card.Text>
                                    <p class="para-small">
                                      A{" "}
                                      <span class="text-theme">
                                        beautiful social calendar
                                      </span>{" "}
                                      that showcases users events by day, week,
                                      month and year.
                                    </p>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <Row className="mt-5 high-z-index">
                    <Col
                      lg={{ span: 6, offset: 3 }}
                      md={{ span: 8, offset: 2 }}
                      sm="12"
                    >
                      <Card className="upcoming-bottom-card">
                        <Card.Body>
                          <Card.Text>
                            <Row>
                              <Col lg="7" md="7" sm="12">
                                <Image
                                  src={upcomingstaricon}
                                  className="img-fluid upcoming-star-icon"
                                  alt="Star Icon"
                                />
                                <h5 class=" text-left">
                                  All future releases are emailed straight to
                                  you <span class="text-theme">for free.</span>
                                </h5>
                              </Col>
                              <Col lg="5" md="5" sm="12">
                                <Link
                                  activeClass="active"
                                  to="pricing"
                                  smooth={true}
                                  offset={100}
                                  duration={500}
                                >
                                  <Button className="btn btn-theme m-0">
                                    Get Socially
                                  </Button>
                                </Link>
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Element name="pricing" className="element">
                <div id="pricing-section">
                  <Container className="high-z-index">
                    <Row>
                      <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        sm="12"
                      >
                        <h2 className="text-headings">
                          Kickstart your design process for a wide variety of
                          apps with socially
                        </h2>
                        <p className="heading-para sub-text">
                          Get access to socially with all future releases for
                          free!{" "}
                          <span class="d-block">
                            <a href="#upcoming-section">
                              <span class="text-theme">
                                Check out our Roadmap
                              </span>{" "}
                            </a>
                            to see what's coming next.
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4" md="12" sm="12">
                        <Card className="price-box">
                          <Card.Body>
                            <ListGroup className="pricetoplist">
                              <ListGroup.Item disabled>
                                Personal & Commercial License
                              </ListGroup.Item>
                              <ListGroup.Item disabled>
                                Live Coded Screens
                              </ListGroup.Item>
                              <ListGroup.Item className="greencheck">
                                Web + Mobile Designs
                              </ListGroup.Item>
                            </ListGroup>
                            <Card.Title className="mt-4">
                              <h1>$59</h1>
                            </Card.Title>
                            <p>Great for designers only</p>
                            <Card.Text>
                              <ListGroup className="featurelist">
                                <ListGroup.Item>
                                  Dashboard Feed Screen
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  Community Screen
                                </ListGroup.Item>
                                <ListGroup.Item>Trending Screen</ListGroup.Item>
                                <ListGroup.Item>
                                  Analytics Screen
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  Mobile Screen Designs (Sketch + Figma)
                                </ListGroup.Item>
                                <ListGroup.Item>Sketch File</ListGroup.Item>
                                <ListGroup.Item>Figma File</ListGroup.Item>
                                <ListGroup.Item>
                                  Adobe XD Source File
                                </ListGroup.Item>
                                <ListGroup.Item>100+ Symbols</ListGroup.Item>
                              </ListGroup>
                            </Card.Text>
                            <a href="https://caviar-designs.myshopify.com/products/socially-designer-package-design-files">
                              <Button
                                className="pricebtngrey "
                                variant="primary"
                              >
                                Get for $59
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="4" md="12" sm="12">
                        <Card className="price-box price-box-theme">
                          <Card.Body>
                            <ListGroup className="pricetoplist">
                              <ListGroup.Item disabled>
                                Personal & Commercial License
                              </ListGroup.Item>
                              <ListGroup.Item className="greencheck">
                                Live Coded Screens
                              </ListGroup.Item>
                              <ListGroup.Item className="greencheck">
                                Web + Mobile Designs
                              </ListGroup.Item>
                            </ListGroup>
                            <Card.Title className="mt-4">
                              <h1>$85</h1>
                            </Card.Title>
                            <p>Great for developers and designers</p>
                            <Card.Text>
                              <ListGroup className="featurelist">
                                <ListGroup.Item>
                                  Source Code to All Screens for web and mobile
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  Everything you see from the Live Preview
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  All items from the designer only package
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  Mobile Screen Designs (Sketch + Figma)
                                </ListGroup.Item>
                                <ListGroup.Item>Sketch File </ListGroup.Item>
                                <ListGroup.Item>Figma File</ListGroup.Item>
                                <ListGroup.Item>
                                  Adobe XD Source File
                                </ListGroup.Item>
                                <ListGroup.Item>100+ Symbols</ListGroup.Item>
                              </ListGroup>
                            </Card.Text>
                            <a href="https://caviar-designs.myshopify.com/products/socially-design-and-code-package-live-code-and-design-files">
                              <Button
                                className="pricebtngrey "
                                variant="primary"
                              >
                                Get for $85
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="4" md="12" sm="12">
                        <Card className="price-box">
                          <Card.Body>
                            <ListGroup className="pricetoplist">
                              <ListGroup.Item className="greencheck">
                                Personal & Commercial License
                              </ListGroup.Item>
                              <ListGroup.Item className="greencheck">
                                Live Coded Screens
                              </ListGroup.Item>
                              <ListGroup.Item className="greencheck">
                                Web + Mobile Designs
                              </ListGroup.Item>
                            </ListGroup>
                            <Card.Title className="mt-4">
                              <h1>$159</h1>
                            </Card.Title>
                            <p>Great for builders and founders</p>
                            <Card.Text>
                              <ListGroup className="featurelist">
                                <ListGroup.Item>
                                  Combine Socially with other works and make a
                                  derivative work from it
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  Create an End Product for a client
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  Source Code to All Screens for web and mobile
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  Everything you see from the Live Preview
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  All items from the designer only package
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  All items from the designer only package
                                </ListGroup.Item>
                                <ListGroup.Item>Sketch File</ListGroup.Item>
                                <ListGroup.Item>Figma File</ListGroup.Item>
                                <ListGroup.Item>
                                  Adobe XD Source File
                                </ListGroup.Item>
                                <ListGroup.Item>100+ Symbols</ListGroup.Item>
                              </ListGroup>
                            </Card.Text>
                            <a href="https://caviar-designs.myshopify.com/products/socially-license-live-code-design-files">
                              <Button
                                className="pricebtngrey "
                                variant="primary"
                              >
                                Get for $159
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="buttonMobPrice">
                      <Button
                        onClick={this.showStater}
                        type="button"
                        className={
                          !this.state.toggleStarter
                            ? "btn btn-theme-grey ml-1 mb-0"
                            : "btn btn-theme ml-1 mb-0"
                        }
                      >
                        Starter
                      </Button>
                      <Button
                        onClick={this.showPremium}
                        type="button"
                        className="btn btn-theme-grey ml-1 mb-0"
                      >
                        Premier
                      </Button>
                      <Button
                        onClick={this.showCombo}
                        type="button"
                        className="btn btn-theme-grey ml-1 mb-0"
                      >
                        Combo
                      </Button>
                      {!this.state.toggleStarter ? (
                        <></>
                      ) : (
                        <Col lg="4" md="12" sm="12">
                          <Card className="price-boxMobile">
                            <Card.Body>
                              <ListGroup className="pricetoplist">
                                <ListGroup.Item disabled>
                                  Personal & Commercial License
                                </ListGroup.Item>
                                <ListGroup.Item disabled>
                                  Live Coded Screens
                                </ListGroup.Item>
                                <ListGroup.Item className="greencheck">
                                  Web + Mobile Designs
                                </ListGroup.Item>
                              </ListGroup>
                              <Card.Title className="mt-4">
                                <h1>$59</h1>
                              </Card.Title>
                              <p>Great for designers only</p>
                              <Card.Text>
                                <ListGroup className="featurelist">
                                  <ListGroup.Item>
                                    Dashboard Feed Screen
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Community Screen
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Trending Screen
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Analytics Screen
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Mobile Screen Designs (Sketch + Figma)
                                  </ListGroup.Item>
                                  <ListGroup.Item>Sketch File</ListGroup.Item>
                                  <ListGroup.Item>Figma File</ListGroup.Item>
                                  <ListGroup.Item>
                                    Adobe XD Source File
                                  </ListGroup.Item>
                                  <ListGroup.Item>100+ Symbols</ListGroup.Item>
                                </ListGroup>
                              </Card.Text>
                              <a href="https://caviar-designs.myshopify.com/products/socially-designer-package-design-files">
                                <Button
                                  className="pricebtngrey "
                                  variant="primary"
                                >
                                  Get for $59
                                </Button>
                              </a>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                      {!this.state.togglePremium ? (
                        <></>
                      ) : (
                        <Col lg="4" md="12" sm="12">
                          <Card className="price-boxMobile price-box-theme">
                            <Card.Body>
                              <ListGroup className="pricetoplist">
                                <ListGroup.Item disabled>
                                  Personal & Commercial License
                                </ListGroup.Item>
                                <ListGroup.Item className="greencheck">
                                  Live Coded Screens
                                </ListGroup.Item>
                                <ListGroup.Item className="greencheck">
                                  Web + Mobile Designs
                                </ListGroup.Item>
                              </ListGroup>
                              <Card.Title className="mt-4">
                                <h1>$85</h1>
                              </Card.Title>
                              <p>Great for developers and designers</p>
                              <Card.Text>
                                <ListGroup className="featurelist">
                                  <ListGroup.Item>
                                    Source Code to All Screens for web and
                                    mobile
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Everything you see from the Live Preview
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    All items from the designer only package
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Mobile Screen Designs (Sketch + Figma)
                                  </ListGroup.Item>
                                  <ListGroup.Item>Sketch File </ListGroup.Item>
                                  <ListGroup.Item>Figma File</ListGroup.Item>
                                  <ListGroup.Item>
                                    Adobe XD Source File
                                  </ListGroup.Item>
                                  <ListGroup.Item>100+ Symbols</ListGroup.Item>
                                </ListGroup>
                              </Card.Text>
                              <a href="https://caviar-designs.myshopify.com/products/socially-design-and-code-package-live-code-and-design-files">
                                <Button
                                  className="pricebtngrey "
                                  variant="primary"
                                >
                                  Get for $85
                                </Button>
                              </a>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                      {!this.state.toggleCombo ? (
                        <></>
                      ) : (
                        <Col lg="4" md="12" sm="12">
                          <Card className="price-boxMobile">
                            <Card.Body>
                              <ListGroup className="pricetoplist">
                                <ListGroup.Item className="greencheck">
                                  Personal & Commercial License
                                </ListGroup.Item>
                                <ListGroup.Item className="greencheck">
                                  Live Coded Screens
                                </ListGroup.Item>
                                <ListGroup.Item className="greencheck">
                                  Web + Mobile Designs
                                </ListGroup.Item>
                              </ListGroup>
                              <Card.Title className="mt-4">
                                <h1>$159</h1>
                              </Card.Title>
                              <p>Great for builders and founders</p>
                              <Card.Text>
                                <ListGroup className="featurelist">
                                  <ListGroup.Item>
                                    Combine Socially with other works and make a
                                    derivative work from it
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Create an End Product for a client
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Source Code to All Screens for web and
                                    mobile
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Everything you see from the Live Preview
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    All items from the designer only package
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    All items from the designer only package
                                  </ListGroup.Item>
                                  <ListGroup.Item>Sketch File</ListGroup.Item>
                                  <ListGroup.Item>Figma File</ListGroup.Item>
                                  <ListGroup.Item>
                                    Adobe XD Source File
                                  </ListGroup.Item>
                                  <ListGroup.Item>100+ Symbols</ListGroup.Item>
                                </ListGroup>
                              </Card.Text>
                              <a href="https://caviar-designs.myshopify.com/products/socially-license-live-code-design-files">
                                <Button
                                  className="pricebtngrey "
                                  variant="primary"
                                >
                                  Get for $159
                                </Button>
                              </a>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </div>
              </Element>
              <div id="faq-section">
                <Container>
                  <Row>
                    <Col
                      lg={{ span: 6, offset: 3 }}
                      md={{ span: 8, offset: 2 }}
                      sm="12"
                    >
                      <h2 className="text-headings">FAQ</h2>
                      <p className="heading-para sub-text">
                        Frequently asked questions, answered.
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col lg="12" md="12" sm="12">
                      <Card className="faq-card">
                        <Card.Body>
                          <Card.Title>
                            Will I get access to all future updates socially
                            offers forever?
                          </Card.Title>
                          <Card.Text>
                            Yes, all future releases of socially will be emailed
                            directly to your inbox once they are out for you to
                            download and use.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card className="faq-card">
                        <Card.Body>
                          <Card.Title>
                            Is there a demo page I can check out before
                            purchasing?
                          </Card.Title>
                          <Card.Text>
                            Yes, the live demo page can be found{" "}
                            <a href="https://socially-demo.eatcaviar.co/">
                              here
                            </a>
                            , live demo page. If you wan't to check anything
                            else out please email alex@eatcaviar.co.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card className="faq-card">
                        <Card.Body>
                          <Card.Title>
                            Will the source code and design files be available?
                          </Card.Title>
                          <Card.Text>
                            All design and source files for the 4 pages that
                            socially currently offers will be available upon
                            purchase and download.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card className="faq-card">
                        <Card.Body>
                          <Card.Title>
                            Where can I understand the licence terms?
                          </Card.Title>
                          <Card.Text>
                            License terms and what you can and can't do with
                            socially can be viewed here.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <div class="faq-footer-container">
                    <Image
                      src={faqFooterImage}
                      className="img-fluid faq-footer"
                      alt="Faq Footer"
                    />
                  </div>
                </Container>
              </div>
            </main>

            <footer id="footer-section">
              <div class="footer-shape-container">
                <Image
                  src={footershape}
                  className="img-fluid footer-shape"
                  alt="Shape"
                  width="100%"
                />
              </div>
              <Container>
                <Row>
                  <Col
                    lg={{ span: 6, offset: 3 }}
                    md={{ span: 8, offset: 2 }}
                    sm="12"
                  >
                    <div className="footer-text">
                      <h1>A modern Social UI Kit built in React</h1>
                      <p className="heading-para text-heading-paragraph">
                        Premium quality, coded social UI Kit build with React.
                      </p>

                      <Link
                        activeClass="active"
                        to="pricing"
                        smooth={true}
                        offset={100}
                        duration={500}
                      >
                        <Button className="btn btn-theme w-175">
                          Get Socially
                        </Button>
                      </Link>
                      <a
                        href="https://socially-demo.eatcaviar.co"
                        target="_blank"
                      >
                        <Button className="btn btn-theme-grey w-175">
                          View live demo
                        </Button>
                      </a>
                    </div>
                  </Col>
                </Row>
                <div className="border-line"></div>
                <Row>
                  <Col lg="8" md="12" sm="12">
                    <div class="footer-link footer-logo-section">
                      <Image
                        src={footerlogo}
                        width="160px"
                        className="d-inline-block align-top"
                        alt="Socially"
                      />
                      <div className="logo-text">
                        <h4 className="text-white">
                          Picked, packed<span className="d-block">shipped</span>
                        </h4>
                      </div>
                      <div class="social-footer-links">
                        <a
                          href="https://dribbble.com/alexbanaga"
                          target="_blank"
                        >
                          <Image
                            src={companydribblelogo}
                            className="d-inline-block align-top onMouse"
                            alt="Company logo"
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/caviar.agency"
                          target="_blank"
                        >
                          <Image
                            src={companyinstalogo}
                            className="d-inline-block align-top onMouse"
                            alt="Company logo"
                          />
                        </a>
                        <a
                          href="https://twitter.com/alexbanaga"
                          target="_blank"
                        >
                          <Image
                            src={companytwitterlogo}
                            className="d-inline-block align-top onMouse"
                            alt="Company logo"
                          />
                        </a>
                      </div>
                      <p className="copy-right-text text-heading-paragraph d-none d-lg-block d-xl-block">
                        Copyright 2020 Caviar, Inc.
                        <span className="d-block">All Rights reserved.</span>
                        <span className="d-block"> Made In San Diego, CA.</span>
                      </p>
                    </div>
                  </Col>
                  <Col lg="2" md="12" sm="12">
                    <div className="footer-link">
                      <p className="para-small text-white">Company</p>
                      <ListGroup className="footer-list">
                        <ListGroup.Item>
                          <a
                            href="javascript:void(0);"
                            className="text-heading-paragraph"
                          >
                            Home
                          </a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <a
                            href="https://socially-demo.eatcaviar.co/"
                            target="_blank"
                            className="text-heading-paragraph"
                          >
                            Full Preview
                          </a>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>
                  <Col lg="2" md="12" sm="12">
                    <div className="footer-link">
                      <p className="para-small text-white">Contact</p>
                      <ListGroup className="footer-list">
                        <ListGroup.Item>
                          <a
                            href="mailto:alex@eatcaviar.co"
                            className="text-heading-paragraph"
                          >
                            alex@eatcaviar.co
                          </a>
                        </ListGroup.Item>
                      </ListGroup>
                      <p className="copy-right-text text-heading-paragraph d-block d-lg-none d-xl-none mt-5">
                        Copyright 2020 Caviar, Inc.
                        <span className="d-block">All Rights reserved.</span>
                        <span className="d-block"> Made In San Diego, CA.</span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </footer>
          </div>
        </OnImagesLoaded>
      </>
    );
  }
}
export default App;
